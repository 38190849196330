export default [
  {
    path: '/courses/:courseId/:sectionId',
    children: [
      {
        path: '',
        component: () => import('@/views/contest/editor/ContestEditorPage.vue'),
        redirect: { name: 'ViewProblem' },
        children: [
          {
            path: 'problem/:slug',
            name: 'ViewProblem',
            component: () => import('@/views/tasks/tabs/task.vue'),
          },
        ],
      },
      {
        path: 'contest-configuration/:id/:index',
        component: () => import('@/views/contest/configuration/ContestConfiguration.vue'),
        name: 'Configuration',
        props: true,
        children: [
          {
            path: ':slug',
            name: 'ToProblem',
            redirect: { name: 'task' },
          },
        ],
      },
    ],
  },
]
