export default [
  {
    path: '/courses/:courseId/:sectionId/contest/:id(\\d+)/:index/:slug',
    component: () => import('@/views/tasks/TaskPage.vue'),
    name: 'ContestView',
    redirect: { name: 'navigation' },
    children: [
      {
        path: 'navigation',
        name: 'navigation',
        component: () => import('@/views/tasks/tabs/navigation.vue'),
      },
      {
        path: 'task',
        name: 'task',
        component: () => import('@/views/tasks/tabs/task.vue'),
      },
      {
        path: 'results/:solutionId',
        name: 'results',
        component: () => import('@/views/tasks/tabs/results.vue'),
      },
      {
        path: 'history',
        name: 'history',
        component: () => import('@/views/tasks/tabs/history.vue'),
      },
      {
        path: 'materials',
        name: 'materials',
        component: () => import('@/views/tasks/tabs/materials.vue'),
      },
    ],
  },
]
