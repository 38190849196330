import ContestRoutes from '../modules/contest'
import TestRoutes from '../modules/test'
import TaskRoutes from './tasks'

export default [
  {
    path: 'courses',
    component: () => import('@/views/courses/course/Courses.vue'),
    name: 'Courses',
  },
  {
    path: 'courses/:courseId',
    component: () => import('@/views/courses/course/Course.vue'),
    name: 'Course',
    children: [
      {
        path: 'edit',
        component: () => import('@/views/courses/course/Course.vue'),
        name: 'Edit',
      },
    ],
  },
  {
    path: 'courses/:courseId/grades',
    component: () => import('@/views/courses/course/CoursesGrade.vue'),
    name: 'Grades',
  },
  {
    path: 'courses/:courseId/:sectionId',
    name: 'Section',
    children: [...TaskRoutes, ...ContestRoutes, ...TestRoutes],
  },
]
